import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  email: any;
  constructor(private http: HttpClient) {}

  get getEmail(): Observable<any> {
    return this.email;
  }

  set setEmail(data: any) {
    this.email = data;
  }

  forgotPassword(data: any): Observable<any> {
    const url = `${environment.baseUrl}/password/forgot`;
    return this.http.post(url, data);
  }

  getEmailDetailByHashKey(emailHash: any) {
    const url = `${environment.baseUrl}/password/validate?email=${emailHash}`;
    return this.http.get(url);
  }

  setPassword(data: any): Observable<any> {
    const url = `${environment.baseUrl}/password/set-password`;
    return this.http.put(url, data);
  }

  setForgetPassword(data: any): Observable<any> {
    const url = `${environment.baseUrl}/password/set-forgot-password`;
    return this.http.put(url, data);
  }
  setOrgPassword(data: any): Observable<any> {
    const url = `${environment.baseUrl}/password/org-set-password`;
    return this.http.put(url, data);
  }
  validOTP(otp: string): Observable<any> {
    const url = `${environment.baseUrl}/password/otp-validate?otp=${otp}`;
    return this.http.get(url);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PasswordResolver implements Resolve<any> {
  constructor(private passwordService: PasswordService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const emailHash = route.data.new
      ? route.queryParams.encrpt
      : route.queryParams.hash;
    return this.passwordService.getEmailDetailByHashKey(emailHash);
  }
}
