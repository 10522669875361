<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 px-0">
      <div class="forget">
        <div class="f-center-div">
          <div class="f-inner-div fr-pad">
            <div class="f-logo-img">
              <div
                class="img"
                [ngStyle]="{
                  'background-image': orgLoginBanner
                    ? 'url(' + img + orgLoginBanner + ')'
                    : 'url(/assets/images/bglogin.jpg)'
                }"
              ></div>
            </div>
            <div class="fr-welcome">Set your account password</div>
            <form [formGroup]="resetForm" class="form">
              <div class="f-input-box mb-3 mt-3">
                <div class="f-label">
                  Communication Preference <span class="required">*</span>
                  <div class="radio">
                    <mat-radio-group
                      formControlName="communicationPreference"
                      (change)="radioChange()"
                    >
                      <mat-radio-button value="email" class="pt-14 radio-label">
                        Email
                      </mat-radio-button>
                      <mat-radio-button value="phone" class="pt-14 radio-label">
                        Phone
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="f-input-box mb-3">
                <div class="f-label">
                  Email
                  <span
                    *ngIf="f.communicationPreference.value === 'email'"
                    class="required"
                    >*</span
                  >
                </div>
                <div class="position-relative">
                  <div>
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      placeholder="example@gmail.com"
                    />
                    <div
                      class="required"
                      *ngIf="
                        f['email'].invalid &&
                        (f['email'].dirty ||
                          f['email'].touched ||
                          invalid.includes('email'))
                      "
                    >
                      <span class="required" *ngIf="f?.email?.errors?.required">
                        Email is required
                      </span>

                      <span class="required" *ngIf="f?.email?.errors?.email">
                        Please enter a valid email
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="f-input-box mb-3">
                <div class="f-label">
                  Phone
                  <span
                    *ngIf="f.communicationPreference.value === 'phone'"
                    class="required"
                    >*</span
                  >
                </div>
                <div class="position-relative">
                  <div>
                    <ngx-intl-tel-input
                      *ngIf="show"
                      Class="noborder"
                      [preferredCountries]="preferredCountry"
                      [enableAutoCountrySelect]="false"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      [numberFormat]="PhoneNumberFormat.National"
                      [inputId]="'phone'"
                      name="phone"
                      formControlName="phone"
                      [maxLength]="'10'"
                    ></ngx-intl-tel-input>
                    <ngx-intl-tel-input
                      *ngIf="!show"
                      Class="noborder"
                      [enablePlaceholder]="true"
                      [preferredCountries]="preferredCountry"
                      [enableAutoCountrySelect]="false"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Name]"
                      [selectFirstCountry]="true"
                      [selectedCountryISO]="CountryISO"
                      [separateDialCode]="true"
                      [numberFormat]="PhoneNumberFormat.National"
                      name="phone"
                      [phoneValidation]="true"
                      formControlName="phone"
                      [maxLength]="'10'"
                    >
                    </ngx-intl-tel-input>
                    <div
                      class="required"
                      *ngIf="
                        f['phone'].invalid &&
                        (f['phone'].dirty ||
                          f['phone'].touched ||
                          invalid.includes('phone'))
                      "
                    >
                      <span class="required" *ngIf="f?.phone?.errors?.required">
                        Phone number is required
                      </span>

                      <span
                        class="required"
                        *ngIf="f?.phone?.errors?.validatePhoneNumber"
                      >
                        Please enter a valid phone number
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="f-input-box mb-3">
                <div class="f-label">
                  Password <span class="required">*</span>
                </div>
                <div class="position-relative">
                  <input
                    class="form-control"
                    [type]="passwordtype"
                    formControlName="password"
                    [ngClass]="{
                      'control-error':
                        f['password'].invalid &&
                        (f['password'].dirty ||
                          f['password'].touched ||
                          invalid.includes('password'))
                    }"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye.png"
                    *ngIf="passwordtype === 'password'"
                    (click)="passwordtype = 'text'"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye-off.svg"
                    *ngIf="passwordtype === 'text'"
                    (click)="passwordtype = 'password'"
                  />
                  <div
                    class="required"
                    *ngIf="
                      f['password'].invalid &&
                      (f['password'].dirty ||
                        f['password'].touched ||
                        invalid.includes('password'))
                    "
                  >
                    <span class="required" *ngIf="f.password?.errors?.required">
                      New password is required
                    </span>
                    <span class="required" *ngIf="f.password?.errors?.pattern">
                      Enter valid password
                    </span>
                  </div>
                </div>
              </div>
              <div class="f-input-box mb-3">
                <div class="f-label">
                  Confirm Password <span class="required">*</span>
                </div>
                <div class="position-relative">
                  <input
                    class="form-control"
                    [type]="confirmpasswordtype"
                    formControlName="confirmPassword"
                    [ngClass]="{
                      'control-error':
                        f['confirmPassword'].invalid &&
                        (f['confirmPassword'].dirty ||
                          f['confirmPassword'].touched ||
                          invalid.includes('confirmPassword'))
                    }"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye.png"
                    *ngIf="confirmpasswordtype === 'password'"
                    (click)="confirmpasswordtype = 'text'"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye-off.svg"
                    *ngIf="confirmpasswordtype === 'text'"
                    (click)="confirmpasswordtype = 'password'"
                  />
                  <div
                    class="required"
                    *ngIf="
                      f['confirmPassword'].invalid &&
                      (f['confirmPassword'].dirty ||
                        f['confirmPassword'].touched ||
                        invalid.includes('confirmPassword'))
                    "
                  >
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.required"
                    >
                      Confirm password is required
                    </span>
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.pattern"
                    >
                      Enter valid password
                    </span>
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.passwordIsForbidden"
                    >
                      Password and confirm password must be same
                    </span>
                  </div>
                </div>
              </div>
              <div class="p-info">
                <div class="p-title">Password Must Contain :</div>
                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.minchar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example1"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Length should be minimum 8 characters</div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.uppercasechar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example2"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">
                    Use minimum one upper case letter (A-Z) & one lower case
                    letter (a-z)
                  </div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      (click)="$event.preventDefault()"
                      [(ngModel)]="passwordstatus.numericchar"
                      [ngModelOptions]="{ standalone: true }"
                      name="example3"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Use minimum one numeric character</div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.specialchar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example3"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Use minimum one special character</div>
                </div>
              </div>
            </form>
            <div class="sign-btn d-flex-ja mt-3" (click)="updatePassword()">
              Confirm Password
            </div>
            <div
              *ngIf="loader"
              class="ingagee-loader"
              style="
                background-color: unset !important;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 100;
                height: 100vh;
              "
            >
              <div class="loadingImage"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
