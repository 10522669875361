import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  key = environment.localStorageEncrptKey; // encryption key
  encryptLocal = environment.encryptLocal; // flag to disable or encrypt localstorage for debugging
  // constructor() { }
   // method to encrpyt the string value
   private encrypt(value:any): string {
    return this.encryptLocal ? CryptoJS.AES.encrypt(value, this.key).toString() : value;
  }

  // method to decrypt the string value
  private decrypt(value:any): string {
    return value
      ? this.encryptLocal
        ? CryptoJS.AES.decrypt(value, this.key).toString(CryptoJS.enc.Utf8)
        : value
      : null;
  }

  // method to set localstorage value
  public setItem(key: string, value: string):any {
    this.removeItem(key);
    localStorage.setItem(this.encrypt(key), this.encrypt(value));
  }

  // method to get localstorage value
  public getItem(key: string) {
    const tokenKey = key;
    return this.decrypt(localStorage.getItem(tokenKey));
  }

   // getUserRole
  //  public getUserRole(): any {
  //   return this.decrypt(localStorage.getItem('userRole'));
  // }

  public getToken(key?: any): any {
    let tokenKey = key;
    if (!key) {
      tokenKey = this.decrypt(localStorage.getItem('token'));
    }
    return this.decrypt(localStorage.getItem(tokenKey));
  }

  // search for the key in the localstorage
  private searchKey(key:string): any {
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
      try {
        if (this.decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }
  }

  // method to remove localstorage value
  public removeItem(key: string) {
    localStorage.removeItem(this.searchKey(key));
  }
}
