<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 px-0">
      <div class="forget">
        <div class="f-center-div">
          <div style="text-align: center" *ngIf="!sentResetApi">
            <img class="mb-4 mt-2 newlim" src="/assets//images/newlogo.png" />
            <div class="f-inner-div">
              <div class="f-logo-img">
                <div
                  class="img"
                  [ngStyle]="{
                    'background-image': 'url(' + img + orgLogo + ')'
                  }"
                ></div>
              </div>
              <div *ngIf="!sentResetApi">
                <div class="f-welcome mb-3">Reset Password</div>
                <div class="f-intro">
                  Enter your username so that we can verify your identity and
                  reset your password
                </div>
                <div class="f-input-box mb-3">
                  <form [formGroup]="forgotPswEmail">
                    <div class="d-flex justify-content-between">
                      <div class="f-label">Username</div>
                      <div class="f-need">Need Help ?</div>
                    </div>
                    <input
                      class="form-control"
                      [ngClass]="{
                        'control-error':
                          forgotPswEmail.controls['email'].invalid &&
                          (forgotPswEmail.controls['email'].dirty ||
                            forgotPswEmail.controls['email'].touched ||
                            forgotPswInvalid.includes('email'))
                      }"
                      type="email"
                      placeholder="Username"
                      formControlName="email"
                      (keyup.enter)="forgotPassword()"
                    />
                    <div
                      *ngIf="
                        forgotPswEmail.controls['email'].invalid &&
                        (forgotPswEmail.controls['email'].dirty ||
                          forgotPswEmail.controls['email'].touched ||
                          forgotPswInvalid.includes('email'))
                      "
                    >
                      <div
                        class="required"
                        *ngIf="forgotPswEmail.controls?.email?.errors?.required"
                      >
                        Username is required
                      </div>
                      <div
                        class="required"
                        *ngIf="forgotPswEmail.controls?.email?.errors?.pattern"
                      >
                        Enter a valid username
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sign-btn d-flex-ja" (click)="forgotPassword()">
                  Submit Request
                </div>
              </div>
            </div>
          </div>
          <div class="f-inner-div fr-pad" *ngIf="sentResetApi">
            <form [formGroup]="resetForm" class="form">
              <div class="f-logo-img">
                <div
                  class="img"
                  [ngStyle]="{
                    'background-image': 'url(' + img + orgLogo + ')'
                  }"
                ></div>
              </div>
              <div class="fr-welcome mb-3">Reset Password</div>
              <div class="f-intro">
                We have sent an OTP to your registered
                {{
                  sendOtpResponse.type
                    ? sendOtpResponse.type === "phone"
                      ? "phone number"
                      : sendOtpResponse.type
                    : "email"
                }}
                {{
                  sendOtpResponse.value
                    ? "(" + sendOtpResponse.value + ")"
                    : ""
                }}. Please enter the OTP below
              </div>
              <div class="f-input-box otp-flex">
                <div>
                  <div class="d-flex justify-content-between">
                    <div class="f-label">
                      Enter your one time password (OTP)
                      <span class="required">*</span> &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;
                    </div>
                  </div>
                  <div class="position-relative">
                    <input
                      class="form-control"
                      placeholder="Enter code"
                      type="text"
                      formControlName="otp"
                      maxlength="6"
                      (keyup.enter)="validateOTPApi()"
                      (keypress)="numberOnly($event)"
                      (paste)="numberOnly($event)"
                      [ngClass]="{
                        'control-error':
                          f['otp'].invalid &&
                          (f['otp'].dirty ||
                            f['otp'].touched ||
                            invalid.includes('otp'))
                      }"
                    />
                    <div
                      class="required"
                      *ngIf="
                        f['otp'].invalid &&
                        (f['otp'].dirty ||
                          f['otp'].touched ||
                          invalid.includes('otp'))
                      "
                    >
                      <span class="required" *ngIf="f.otp?.errors?.required">
                        OTP is required
                      </span>
                      <span class="required" *ngIf="f.otp?.errors?.minlength">
                        The OTP should be at least 6 digits long
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="!otpValid"
                  class="sign-btn valid-btn d-flex-ja mt-3"
                  (click)="validateOTPApi()"
                >
                  Validate
                </div>
                <div
                  *ngIf="otpValid"
                  class="sign-btn valid-btn d-flex-ja mt-3 otp-disabled"
                >
                  Validate
                </div>
              </div>
              <div class="d-flex-ja" *ngIf="!otpValid">
                Haven’t received a code?
                <span class="d-flex-ja resend" (click)="forgotPassword()"
                  >Resend</span
                >
              </div>
              <div *ngIf="otpValid">
                <div class="f-input-box mb-3 mt-3">
                  <div class="f-label">
                    Password <span class="required">*</span>
                  </div>
                  <div class="position-relative">
                    <input
                      class="form-control"
                      [type]="passwordtype"
                      formControlName="password"
                      (keyup.enter)="updatePassword()"
                      [ngClass]="{
                        'control-error':
                          f['password'].invalid &&
                          (f['password'].dirty ||
                            f['password'].touched ||
                            invalid.includes('password'))
                      }"
                    />
                    <img
                      class="ps-img"
                      src="/assets/images/eye.png"
                      *ngIf="passwordtype === 'password'"
                      (click)="passwordtype = 'text'"
                    />
                    <img
                      class="ps-img"
                      src="/assets/images/eye-off.svg"
                      *ngIf="passwordtype === 'text'"
                      (click)="passwordtype = 'password'"
                    />
                    <div
                      class="required"
                      *ngIf="
                        f['password'].invalid &&
                        (f['password'].dirty ||
                          f['password'].touched ||
                          invalid.includes('password'))
                      "
                    >
                      <span
                        class="required"
                        *ngIf="f.password?.errors?.required"
                      >
                        New password is required
                      </span>
                      <span
                        class="required"
                        *ngIf="f.password?.errors?.pattern"
                      >
                        Enter valid password
                      </span>
                    </div>
                  </div>
                </div>
                <div class="f-input-box mb-3">
                  <div class="f-label">
                    Confirm Password <span class="required">*</span>
                  </div>
                  <div class="position-relative">
                    <input
                      class="form-control"
                      [type]="confirmpasswordtype"
                      formControlName="confirmPassword"
                      (keyup.enter)="updatePassword()"
                      [ngClass]="{
                        'control-error':
                          f['confirmPassword'].invalid &&
                          (f['confirmPassword'].dirty ||
                            f['confirmPassword'].touched ||
                            invalid.includes('confirmPassword'))
                      }"
                    />
                    <img
                      class="ps-img"
                      src="/assets/images/eye.png"
                      *ngIf="confirmpasswordtype === 'password'"
                      (click)="confirmpasswordtype = 'text'"
                    />
                    <img
                      class="ps-img"
                      src="/assets/images/eye-off.svg"
                      *ngIf="confirmpasswordtype === 'text'"
                      (click)="confirmpasswordtype = 'password'"
                    />
                    <div
                      class="required"
                      *ngIf="
                        f['confirmPassword'].invalid &&
                        (f['confirmPassword'].dirty ||
                          f['confirmPassword'].touched ||
                          invalid.includes('confirmPassword'))
                      "
                    >
                      <span
                        class="required"
                        *ngIf="f.confirmPassword?.errors?.required"
                      >
                        Confirm password is required
                      </span>
                      <span
                        class="required"
                        *ngIf="f.confirmPassword?.errors?.pattern"
                      >
                        Enter valid password
                      </span>
                      <span
                        class="required"
                        *ngIf="f.confirmPassword?.errors?.passwordIsForbidden"
                      >
                        Password and confirm password must be same
                      </span>
                    </div>
                  </div>
                </div>
                <div class="p-info">
                  <div class="p-title">Password Must Contain :</div>
                  <div class="p-dis d-flex-a">
                    <label class="container1">
                      <input
                        type="checkbox"
                        readonly="true"
                        [(ngModel)]="passwordstatus.minchar"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="$event.preventDefault()"
                        name="example1"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <div class="flex1">
                      Length should be minimum 8 characters
                    </div>
                  </div>

                  <div class="p-dis d-flex-a">
                    <label class="container1">
                      <input
                        type="checkbox"
                        readonly="true"
                        [(ngModel)]="passwordstatus.uppercasechar"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="$event.preventDefault()"
                        name="example2"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <div class="flex1">
                      Use minimum one upper case letter (A-Z) & one lower case
                      letter (a-z)
                    </div>
                  </div>

                  <div class="p-dis d-flex-a">
                    <label class="container1">
                      <input
                        type="checkbox"
                        readonly="true"
                        (click)="$event.preventDefault()"
                        [(ngModel)]="passwordstatus.numericchar"
                        [ngModelOptions]="{ standalone: true }"
                        name="example3"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <div class="flex1">Use minimum one numeric character</div>
                  </div>

                  <div class="p-dis d-flex-a">
                    <label class="container1">
                      <input
                        type="checkbox"
                        readonly="true"
                        [(ngModel)]="passwordstatus.specialchar"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="$event.preventDefault()"
                        name="example3"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <div class="flex1">Use minimum one special character</div>
                  </div>
                </div>
                <div class="sign-btn d-flex-ja mt-3" (click)="updatePassword()">
                  Reset Password
                </div>
              </div>
            </form>
          </div>
          <div class="forget-footer">
            If you haven't made this request, &nbsp;
            <a href="#"> Click here </a> &nbsp; to login session
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="loader"
      class="ingagee-loader"
      style="
        background-color: unset !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        height: 100vh;
      "
    >
      <div class="loadingImage"></div>
    </div>
  </div>
</div>
