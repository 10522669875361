import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { InvalidFormFieldsService } from '../../services/invalid-form-fields.service';
// import { LocalStorageService } from '../../services/local-storage.service';
import { PasswordService } from '../../services/password.service';

@Component({
  selector: 'ing-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  currentRoute: any;
  forgotPswEmail: any = FormGroup;
  showContent = false;

  passwordtype: any = 'password';
  confirmpasswordtype: any = 'password';

  resetForm: any = FormGroup;
  loader :any = true;
  submitted:any = false;

  passwordstatus: {
    minchar: any;
    specialchar: any;
    numericchar: any;
    uppercasechar: any;
  } = {
    minchar: false,
    specialchar: false,
    numericchar: false,
    uppercasechar: false,
  };

  userdetail: any = null;
  newPassword = false;
  emailData: any;
  invalid: any = [];
  orgLogo: any;
  img = `${environment.baseUrl}${environment.fileUrl}`;
  constructor(
    private toastr: CustomToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private invalidFormService: InvalidFormFieldsService,
    // private localStorage: LocalStorageService
  ) {}
  ngOnInit(): void {
    this.orgLogo = localStorage.getItem('logo');
    this.emailForm();
    this.checkRoute();
    if (this.currentRoute === 'new-password') {
      this.newPassword = true;
      this.reset();
      this.route.data.subscribe((email) => (this.emailData = email));
      this.passwordStatusDesider();
    } else if (this.currentRoute === 'reset-password') {
      this.reset();
      this.route.data.subscribe((email) => (this.emailData = email));
      this.passwordStatusDesider();
    }
  }

  reset() {
    this.resetForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
      },
      {
        validators: this.passwordConfirmPasswordF,
      }
    );
  }

  passwordConfirmPasswordF(FGroup: any): any {
    const {
      controls: { confirmPassword, password },
    } = FGroup;
    if (
      confirmPassword.value &&
      password.value &&
      !Object.is(confirmPassword.value, password.value)
    ) {
      confirmPassword.setErrors({ passwordIsForbidden: true });
    } else if (
      confirmPassword.value &&
      confirmPassword.errors &&
      confirmPassword.errors.passwordIsForbidden
    ) {
      confirmPassword.setErrors(null);
    }
    return null;
  }

  passwordStatusDesider(): void {
    this.resetForm.controls.password.valueChanges.subscribe((password: any) => {
      this.passwordstatus = {
        minchar: /^.{8,24}$/gim.test(password),
        specialchar: /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/gi.test(password),
        numericchar: /^(?=.*[0-9]).*$/gi.test(password),
        uppercasechar: /^(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(password),
      };
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  checkRoute(): any {
    this.currentRoute = this.route.routeConfig?.path;
  }

  emailForm() {
    const email = this.passwordService.getEmail;
    this.forgotPswEmail = new FormGroup({
      email: new FormControl(email, [Validators.required, Validators.email]),
    });
  }

  forgotPassword(): any {
    if (this.forgotPswEmail.invalid) {
      this.forgotPswEmail.markAsTouched();
    } else {
      this.submitted = true;
      const resetemail = this.forgotPswEmail.value;

      this.passwordService.forgotPassword(resetemail).subscribe(
        (res: any) => {
          if (res) {
            this.submitted = false;
            this.toastr.success(
              `Reset password link has been sent to your email`
            );
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          this.submitted = false;
          this.toastr.error(
            err.message ? err.message : 'Failed to send reset password link'
          );
        }
      );
    }
  }

  updatePassword(): any {
    if (this.resetForm.invalid) {
      this.invalid = this.invalidFormService.fields(this.resetForm.controls);
    } else {
      const email = this.emailData.emailData.email_id;
      const { password, confirmPassword } = this.resetForm.value;
      this.passwordService
        .setPassword({ email, password, confirmPassword })
        .subscribe(
          () => {
            this.toastr.success(`Password has been created successfully`);
            localStorage.clear();
            this.router.navigate(['/login']);
          },
          (error: any) => {
            this.toastr.error(
              error.message ? error.message : 'Error while updating password'
            );
          }
        );
    }
  }
}
