import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
// import { ToastrService } from 'ngx-toastr';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
// services
import { AuthService } from '../services/auth.service';
import { CustomToastrService } from './custom-toastr.service';
@Injectable()
export class JwtAuthInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  constructor(
    private authService: AuthService,
    private toastr: CustomToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAdminToken();
    return next.handle(this.addAuthorizationHeader(request, accessToken)).pipe(
      tap((evt) => {
        // sometime 403 response come from success part
        if (evt instanceof HttpResponse) {
          if (evt.status !== 403) {
            return;
          }
        }
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // get refresh tokens
          const refreshToken = this.authService.getRefreshToken();
          // if there are tokens then send refresh token request
          if (
            refreshToken &&
            !this.authService.isAuthenticated('refreshToken') &&
            accessToken
          ) {
            return this.refreshToken(request, next);
          } else {
            return this.logoutAndRedirect(err);
          }
          // otherwise logout and redirect to login page
          // return this.logoutAndRedirect(err);
          // return EMPTY;
        }

        // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 403) {
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
          // return EMPTY;
        }
        // if error has status neither 401 nor 403 then just return this error
        // this.toaster.error(err.error && err.error.error ? err.error.error : 'Something Went Wrong');
        return this.handleError(err);
      })
    );
  }
  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage =
        'Error:' + error.error && error.error.error
          ? error.error.error
          : 'Oops!!! Something went wrong';
    } else {
      // server-side error
      errorMessage =
        error.error && error.error.error
          ? error.error.error
          : 'Oops!!! Something went wrong';
    }
    return throwError(errorMessage);
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    }

    return request;
  }

  private logoutAndRedirect(err: any): Observable<HttpEvent<any>> {
    this.authService.logout().subscribe(
      () => {
        this.toastr.clear();
        this.toastr.error('Session expired!!! Please login again');
        this.authService.removeToken();
      },
      (error: any) => {
        if (error) {
          this.toastr.clear();
          this.toastr.error('Session expired!!! Please login again');
          this.authService.removeToken();
        }
      }
    );
    // return throwError(err);
    return this.handleError(err);
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next('');

      return this.authService.refreshToken().pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.accessToken);
          // repeat failed request with new token
          return next.handle(
            this.addAuthorizationHeader(request, res.accessToken)
          );
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token: any) => token),
        take(1),
        switchMap((token) => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token));
        })
      );
    }
  }
}
