import { Component, OnInit } from '@angular/core';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { HeaderSearchService } from './shared/services/header-search.service';
@Component({
  selector: 'ing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ingagee-portal';
  loading = true;

  constructor(
    private router: Router,
    private headerSearchService: HeaderSearchService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
    setTimeout(() => {
      if (localStorage.getItem('language')) {
        const localsetlangage = localStorage.getItem('language');
        this.headerSearchService.setLanguage(localsetlangage as any);
      }
    }, 100);
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (!event.storageArea?.length) {
        this.router.navigate(['/login']);
      }
    });
  }
}
