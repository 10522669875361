import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeaderSearchService {
  profileUserData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  headerSearch = new Subject<any>();
  language: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getHeaderSearchtype(): Observable<any> {
    this.headerSearch.next('');
    return this.headerSearch.pipe(debounceTime(1000), distinctUntilChanged());
  }

  setHeaderSearchType(val: any) {
    this.headerSearch.next(val);
  }

  setLanguage(val: string) {
    this.language.next(val);
  }
  getLanguage(): Observable<string> {
    return this.language.asObservable();
  }

  setUserProfileData(val: any) {
    this.profileUserData.next(val);
  }

  getuserProfileData() {
    return this.profileUserData.asObservable();
  }

  getNotifications(): Observable<any> {
    const url = environment.baseUrl + `/notifications?read=false`;
    return this.http.get(url);
  }

  updateNotifications(updateData: any): Observable<any> {
    const url = environment.baseUrl + `/notifications`;
    return this.http.put(url, updateData);
  }
}
