import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { InvalidFormFieldsService } from '../../services/invalid-form-fields.service';
import { PasswordService } from '../../services/password.service';
import { LoginService } from '../../services/login.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { AuthService } from '../../services/auth.service';
import { PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'ing-org-new-password',
  templateUrl: './org-new-password.component.html',
  styleUrls: ['./org-new-password.component.scss'],
})
export class OrgNewPasswordComponent implements OnInit {
  currentRoute: any;
  forgotPswEmail: any = FormGroup;
  showContent = false;

  passwordtype: any = 'password';
  confirmpasswordtype: any = 'password';

  resetForm: any = FormGroup;
  loader: any = false;
  submitted: any = false;

  passwordstatus: {
    minchar: any;
    specialchar: any;
    numericchar: any;
    uppercasechar: any;
  } = {
    minchar: false,
    specialchar: false,
    numericchar: false,
    uppercasechar: false,
  };

  userdetail: any = null;
  newPassword = false;
  emailData: any;
  invalid: any = [];
  img = `${environment.baseUrl}${environment.fileUrl}`;
  orgLoginBanner = '';

  SearchCountryField = SearchCountryField;
  CountryISO: any = 'us';
  preferredCountry = [];
  PhoneNumberFormat = PhoneNumberFormat;

  enablingCountryLoadStatus: any = true;
  JSON: any;
  show = true;

  constructor(
    private toastr: CustomToastrService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private passwordService: PasswordService,
    private invalidFormService: InvalidFormFieldsService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.reset();
    this.passwordStatusDesider();
    if (this.activeRouter.snapshot.queryParams.orgLogo) {
      this.orgLoginBanner = this.activeRouter.snapshot.queryParams.orgLogo;
    }

    if (
      this.activeRouter.snapshot.queryParams?.countryCode &&
      this.enablingCountryLoadStatus
    ) {
      this.CountryISO = this.activeRouter.snapshot.queryParams.countryCode;
    }

    if (this.activeRouter.snapshot.queryParams?.phoneNumber) {
      this.resetForm.patchValue({
        phone: this.activeRouter.snapshot.queryParams.phoneNumber,
      });
    }

    if (this.activeRouter.snapshot.queryParams?.email) {
      this.resetForm.patchValue({
        email: this.activeRouter.snapshot.queryParams.email,
      });
    }
    if (this.activeRouter.snapshot.queryParams?.communicationPreference) {
      this.resetForm.patchValue({
        communicationPreference:
          this.activeRouter.snapshot.queryParams.communicationPreference,
      });
      this.radioChange();
    }
  }

  addValidation(keyValue: string) {
    this.f[keyValue].setValidators([
      Validators.required,
      ...(keyValue === 'email' ? [Validators.email] : []),
    ]);
    this.f[keyValue].updateValueAndValidity();
  }

  removeValidation(keyValue: string) {
    this.f[keyValue].setValidators(
      keyValue === 'email' ? Validators.email : null
    );
    this.f[keyValue].updateValueAndValidity();
  }
  radioChange() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
      if (this.f.communicationPreference.value === 'email') {
        this.removeValidation('phone');
        this.addValidation(this.f.communicationPreference.value);
      } else if (this.f.communicationPreference.value === 'phone') {
        this.removeValidation('email');
        this.addValidation(this.f.communicationPreference.value);
      }
    }, 500);

    this.resetForm.markAsTouched();
  }

  reset() {
    this.resetForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
        communicationPreference: new FormControl('email', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl(''),
      },
      {
        validators: this.passwordConfirmPasswordF,
      }
    );
  }

  passwordConfirmPasswordF(FGroup: any): any {
    const {
      controls: { confirmPassword, password },
    } = FGroup;
    if (
      confirmPassword.value &&
      password.value &&
      !Object.is(confirmPassword.value, password.value)
    ) {
      confirmPassword.setErrors({ passwordIsForbidden: true });
    } else if (
      confirmPassword.value &&
      confirmPassword.errors &&
      confirmPassword.errors.passwordIsForbidden
    ) {
      confirmPassword.setErrors(null);
    }
    return null;
  }

  passwordStatusDesider(): void {
    this.resetForm.controls.password.valueChanges.subscribe((password: any) => {
      this.passwordstatus = {
        minchar: /^.{8,24}$/gim.test(password),
        specialchar: /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/gi.test(password),
        numericchar: /^(?=.*[0-9]).*$/gi.test(password),
        uppercasechar: /^(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(password),
      };
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  updatePassword(): any {
    if (this.resetForm.invalid) {
      this.invalid = this.invalidFormService.fields(this.resetForm.controls);
    } else {
      const hash = this.activeRouter.snapshot.queryParams.passHash;
      const {
        password,
        confirmPassword,
        communicationPreference,
        email,
        phone,
      } = this.resetForm.value;
      this.loader = true;
      this.passwordService
        .setOrgPassword({
          hash,
          password,
          confirmPassword,
          communicationPreference,
          email: email ? email : '',
          phoneNumbercountryShortCode:
            phone && phone.countryCode ? phone.countryCode : '',
          phoneDialingCode: phone && phone.dialCode ? phone.dialCode : '',
          phoneNumber: phone && phone.number ? phone.number : '',
        })
        .subscribe(
          () => {
            this.toastr.success(`Password has been created successfully`);
            localStorage.clear();
            this.loginApiCall(
              this.activeRouter.snapshot.queryParams.userName,
              password
            );
          },
          (error: any) => {
            this.loader = false;
            this.toastr.error(
              error.message ? error.message : 'Error while updating password'
            );
          }
        );
    }
  }
  loginApiCall(userName: string, pass: string) {
    this.loginService.login({ email: userName, password: pass }).subscribe(
      (loginRes: any) => {
        this.loader = false;
        this.localStorageService.setItem('token', loginRes.accessToken);
        this.localStorageService.setItem('refreshToken', loginRes.refreshToken);

        const token = this.authService.tokenDecode();
        const navigatePath = this.authService.redirectTo(token.role_id);
        this.router.navigate([navigatePath]);
      },
      () => {
        this.loader = false;
        this.router.navigate(['/login']);
      }
    );
  }
}
