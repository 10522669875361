import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'ing-profile-view-shell',
  templateUrl: './profile-view-shell.component.html',
  styleUrls: ['./profile-view-shell.component.scss'],
})
export class ProfileViewShellComponent implements OnInit {
  id: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const token = this.auth.tokenDecode();

    this.route.queryParams.subscribe((val: any) => {
      this.id = val.profile;
    });
    if (token) {
      const user =
        token?.role_id === 2
          ? 'org-admin'
          : token?.role_id === 3
          ? 'manager'
          : 'employee';
      this.router.navigate([`/${user}/view-profile/${this.id}`]);
    } else {
      this.router.navigate(['/login'], { queryParams: { id: this.id } });
    }
  }
}
