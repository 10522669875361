import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InvalidFormFieldsService {
  invalid: any = [];
  // constructor() { }

  fields(controls: any) {
    this.invalid = [];
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    return this.invalid;
  }

  fieldsMarkAsTouched(fGroup: FormGroup): any {
    (function formgrouptouch(formGroup: any): any {
      Object.values(formGroup).forEach((control: any) => {
        control.markAsTouched();
        if (control.controls) {
          formgrouptouch(control.controls);
        }
      });
    })(fGroup.controls);
  }
}
