import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { InvalidFormFieldsService } from '../../services/invalid-form-fields.service';
// import { LocalStorageService } from '../../services/local-storage.service';
import { PasswordService } from '../../services/password.service';

@Component({
  selector: 'ing-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  currentRoute: any;
  forgotPswEmail: any = FormGroup;
  showContent = false;

  passwordtype: any = 'password';
  confirmpasswordtype: any = 'password';

  resetForm: any = FormGroup;
  loader: any = false;
  // submitted: any = false;

  passwordstatus: {
    minchar: any;
    specialchar: any;
    numericchar: any;
    uppercasechar: any;
  } = {
    minchar: false,
    specialchar: false,
    numericchar: false,
    uppercasechar: false,
  };

  userdetail: any = null;
  newPassword = false;
  // emailData: any;
  invalid: any = [];
  orgLogo: any;
  img = `${environment.baseUrl}${environment.fileUrl}`;
  sentResetApi = false;
  otp = '';
  sendOtpResponse: { type: string; value: string } = { type: '', value: '' };
  otpValid = false;
  hashKey = '';
  forgotPswInvalid: any = [];

  constructor(
    private toastr: CustomToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private invalidFormService: InvalidFormFieldsService // private localStorage: LocalStorageService
  ) {}
  ngOnInit(): void {
    this.orgLogo = localStorage.getItem('logo');
    this.emailForm();
    this.checkRoute();
    this.reset();
    this.passwordStatusDesider();
  }

  reset() {
    this.resetForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
          ),
        ]),
        otp: new FormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      {
        validators: this.passwordConfirmPasswordF,
      }
    );
  }

  passwordConfirmPasswordF(FGroup: any): any {
    const {
      controls: { confirmPassword, password },
    } = FGroup;
    if (
      confirmPassword.value &&
      password.value &&
      !Object.is(confirmPassword.value, password.value)
    ) {
      confirmPassword.setErrors({ passwordIsForbidden: true });
    } else if (
      confirmPassword.value &&
      confirmPassword.errors &&
      confirmPassword.errors.passwordIsForbidden
    ) {
      confirmPassword.setErrors(null);
    }
    return null;
  }

  passwordStatusDesider(): void {
    this.resetForm.controls.password.valueChanges.subscribe((password: any) => {
      this.passwordstatus = {
        minchar: /^.{8,24}$/gim.test(password),
        specialchar: /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/gi.test(password),
        numericchar: /^(?=.*[0-9]).*$/gi.test(password),
        uppercasechar: /^(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(password),
      };
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  checkRoute(): any {
    this.currentRoute = this.route.routeConfig?.path;
  }

  emailForm() {
    const email = this.passwordService.getEmail;
    this.forgotPswEmail = new FormGroup({
      email: new FormControl(email, [
        Validators.required,
        Validators.pattern(/^(?!\s)(.*\S)$/),
      ]),
    });
  }

  forgotPassword(): any {
    if (this.forgotPswEmail.invalid) {
      this.forgotPswInvalid = this.invalidFormService.fields(
        this.forgotPswEmail.controls
      );
      this.forgotPswEmail.markAsTouched();
    } else {
      this.loader = true;
      const resetemail = this.forgotPswEmail.value;

      this.passwordService.forgotPassword(resetemail).subscribe(
        (res: any) => {
          if (res) {
            if (!this.sendOtpResponse) {
              this.toastr.error('OTP has been sent successfully');
            }
            this.loader = false;
            this.sentResetApi = true;
            if (res && res.data) {
              this.sendOtpResponse = res.data;
            }
          }
        },
        (err) => {
          this.loader = false;
          this.toastr.error(
            err.message ? err.message : 'Failed to send reset password'
          );
        }
      );
    }
  }

  validateOTPApi(): any {
    if (this.f['otp'].invalid) {
      this.invalid = this.invalidFormService.fields(this.resetForm.controls);
      this.resetForm.markAsTouched();
    } else {
      const { otp } = this.resetForm.getRawValue();
      this.loader = true;
      this.passwordService.validOTP(otp).subscribe(
        (res) => {
          this.loader = false;
          if (res && res.data && res.data.hash) {
            this.hashKey = res.data.hash;
            this.otpValid = true;
            this.f['otp'].disable();
            this.f['password'].setValidators([
              Validators.required,
              Validators.pattern(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
              ),
            ]);
            this.f['confirmPassword'].setValidators([
              Validators.required,
              Validators.pattern(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~|])[A-Za-z0-9!@#$%^&*~|]{8,25}$/
              ),
            ]);
          }
        },
        (error: any) => {
          this.loader = false;
          this.toastr.error(
            error.message ? error.message : 'Error while valid OTP'
          );
        }
      );
    }
  }
  updatePassword(): any {
    if (this.resetForm.invalid) {
      this.invalid = this.invalidFormService.fields(this.resetForm.controls);
      this.resetForm.markAsTouched();
    } else {
      const { password, confirmPassword, otp } = this.resetForm.getRawValue();
      this.loader = true;
      const bodyObj = {
        password: password,
        confirmPassword: confirmPassword,
        otp,
        hash: this.hashKey,
      };
      this.passwordService.setForgetPassword(bodyObj).subscribe(
        () => {
          this.loader = false;
          this.toastr.success(`Password has been updated successfully`);
          localStorage.clear();
          this.router.navigate(['/login']);
        },
        (error: any) => {
          this.loader = false;
          this.toastr.error(
            error.message ? error.message : 'Error while updating password'
          );
        }
      );
    }
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (event?.type == 'paste') {
      const windows: any = window;
      const reg = new RegExp('^[0-9]+$');
      var clipboardData =
        event.clipboardData ||
        event.originalEvent.clipboardData ||
        windows.clipboardData;
      var pastedData = clipboardData.getData('text');
      const numberOnlyValid = reg.test(pastedData);
      if (numberOnlyValid) {
        return true;
      }
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
