import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomToastrService {
  constructor(private toastr: ToastrService) {}

  success(message: any) {
    this.showSuccessMessage(
      `<div class="d-flex-a"><i class="fas fa-check-circle"></i><div><div class="f-14">Success</div><div class="f-12">${message}.</div></div></div>`
    );
  }

  showSuccessMessage(message: any) {
    this.toastr.success(message, '', {
      enableHtml: true,
    });
  }

  error(message: any) {
    this.showErrorMessage(
      `<div class="d-flex-a"><i class="fas fa-times-circle"></i><div><div class="f-14">Error</div><div class="f-12">${message}.</div></div></div>`
    );
  }

  showErrorMessage(message: any) {
    this.toastr.error(message, '', {
      enableHtml: true,
    });
  }

  clear() {
    this.toastr.clear();
  }
}
