<div class="app-component">
  <router-outlet></router-outlet>
</div>

<div
  class="ingagee-loader"
  *ngIf="loading"
  style="
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
  "
>
  <div class="loadingImage" style="height: 70px !important"></div>
</div>
