import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  checkEmail(data: any): Observable<any> {
    const url = `${environment.baseUrl}/login/check`;
    return this.http.post(url, data);
  }

  login(data: any): Observable<any> {
    const url = `${environment.baseUrl}/login`;
    return this.http.post(url, data);
  }
}
