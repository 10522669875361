import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeGuard } from './employees/guards/employee.guard';
import { ManagerGuard } from './manager/guards/manager.guard';
import { OrgAdminGuard } from './org-admin/guards/org-admin.guard';
import { ProductAdminGuard } from './product-admin/guards/product-admin.guard';
import { NewPasswordComponent } from './shared/components/new-password/new-password.component';
import { ProfileViewShellComponent } from './shared/components/profile-view-shell/profile-view-shell.component';
import { LoginGuard } from './shared/guards/login.guard';
import { PasswordResolver } from './shared/services/password.service';
import { OrgNewPasswordComponent } from './shared/components/org-new-password/org-new-password.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((mod) => mod.LoginModule),
    canLoad: [LoginGuard],
  },
  {
    path: 'new-org-password',
    component: OrgNewPasswordComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'new-password',
    component: NewPasswordComponent,
    resolve: {
      emailData: PasswordResolver,
    },
    data: { new: true },
    canActivate: [LoginGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [LoginGuard],
    // resolve: {
    //   emailData: PasswordResolver,
    // },
  },
  {
    path: 'reset-password',
    component: NewPasswordComponent,
    resolve: {
      emailData: PasswordResolver,
    },
    canActivate: [LoginGuard],
  },
  {
    path: 'view',
    component: ProfileViewShellComponent,
  },
  {
    path: 'product-admin',
    loadChildren: () =>
      import('./product-admin/product-admin.module').then(
        (mod) => mod.ProductAdminModule
      ),
    canLoad: [ProductAdminGuard],
  },
  {
    path: 'org-admin',
    loadChildren: () =>
      import('./org-admin/org-admin.module').then((mod) => mod.OrgAdminModule),
    canLoad: [OrgAdminGuard],
  },
  {
    path: 'manager',
    loadChildren: () =>
      import('./manager/manager.module').then((mod) => mod.ManagerModule),
    canLoad: [ManagerGuard],
  },
  {
    path: 'employee',
    loadChildren: () =>
      import('./employees/employees.module').then((mod) => mod.EmployeesModule),
    canLoad: [EmployeeGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
