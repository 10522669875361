<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 px-0">
      <div class="forget">
        <div class="f-center-div">
          <div
            style="text-align: center"
            *ngIf="currentRoute === 'forgot-password'"
          >
            <img class="mb-4 mt-2 newlim" src="/assets//images/newlogo.png" />
            <div class="f-inner-div">
              <div class="f-logo-img">
                <div
                  class="img"
                  [ngStyle]="{
                    'background-image': 'url(' + img + orgLogo + ')'
                  }"
                ></div>
              </div>
              <div class="f-welcome mb-3">Reset Password</div>
              <div class="f-intro">
                If you forgot your password, well, then we’ll email you
                instructions to reset your password.
              </div>
              <div class="f-input-box mb-3">
                <form [formGroup]="forgotPswEmail">
                  <div class="d-flex justify-content-between">
                    <div class="f-label">Email</div>
                    <div class="f-need">Need Help ?</div>
                  </div>
                  <input
                    class="form-control"
                    type="email"
                    formControlName="email"
                  />
                </form>
              </div>
              <div class="sign-btn d-flex-ja" (click)="forgotPassword()">
                Send Reset Link
              </div>
            </div>
          </div>
          <!-- <div class="f-inner-div" style="display: none;">
                        <div class="f-logo-img">
                            <img src="/assets/images/itech.png"/>
                        </div>
                        <div class="f-welcome">Reset Password</div>
                        <div class="f-intro text-start mt-3 ps-4">Welcome!</div>
                        <div class="f-intro text-start px-4"> Based on your request to reset your password to access your account, please click the below button to proceed further.</div>
                        <div class="sign-btn d-flex-ja mx-6 text-uppercase">Reset Password</div>
                    </div> -->
          <div
            class="f-inner-div fr-pad"
            *ngIf="currentRoute !== 'forgot-password'"
          >
            <div class="f-logo-img d-flex-ja">
              <div
                class="img"
                style="background-image: url(/assets/images/itech.png)"
              ></div>
            </div>
            <div class="fr-welcome">Set your account password</div>
            <form [formGroup]="resetForm" class="form">
              <div class="f-input-box mb-3 mt-3">
                <div class="f-label">
                  Password <span class="required">*</span>
                </div>
                <div class="position-relative">
                  <input
                    class="form-control"
                    [type]="passwordtype"
                    formControlName="password"
                    [ngClass]="{
                      'control-error':
                        f['password'].invalid &&
                        (f['password'].dirty ||
                          f['password'].touched ||
                          invalid.includes('password'))
                    }"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye.png"
                    *ngIf="passwordtype === 'password'"
                    (click)="passwordtype = 'text'"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye-off.svg"
                    *ngIf="passwordtype === 'text'"
                    (click)="passwordtype = 'password'"
                  />
                  <div
                    class="required"
                    *ngIf="
                      f['password'].invalid &&
                      (f['password'].dirty ||
                        f['password'].touched ||
                        invalid.includes('password'))
                    "
                  >
                    <span class="required" *ngIf="f.password?.errors?.required">
                      New password is required
                    </span>
                    <span class="required" *ngIf="f.password?.errors?.pattern">
                      Enter valid password
                    </span>
                  </div>
                </div>
              </div>
              <div class="f-input-box mb-3">
                <div class="f-label">
                  Confirm Password <span class="required">*</span>
                </div>
                <div class="position-relative">
                  <input
                    class="form-control"
                    [type]="confirmpasswordtype"
                    formControlName="confirmPassword"
                    [ngClass]="{
                      'control-error':
                        f['confirmPassword'].invalid &&
                        (f['confirmPassword'].dirty ||
                          f['confirmPassword'].touched ||
                          invalid.includes('confirmPassword'))
                    }"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye.png"
                    *ngIf="confirmpasswordtype === 'password'"
                    (click)="confirmpasswordtype = 'text'"
                  />
                  <img
                    class="ps-img"
                    src="/assets/images/eye-off.svg"
                    *ngIf="confirmpasswordtype === 'text'"
                    (click)="confirmpasswordtype = 'password'"
                  />
                  <div
                    class="required"
                    *ngIf="
                      f['confirmPassword'].invalid &&
                      (f['confirmPassword'].dirty ||
                        f['confirmPassword'].touched ||
                        invalid.includes('confirmPassword'))
                    "
                  >
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.required"
                    >
                      Confirm password is required
                    </span>
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.pattern"
                    >
                      Enter valid password
                    </span>
                    <span
                      class="required"
                      *ngIf="f.confirmPassword?.errors?.passwordIsForbidden"
                    >
                      Password and confirm password must be same
                    </span>
                  </div>
                </div>
              </div>
              <div class="p-info">
                <div class="p-title">Password Must Contain :</div>
                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.minchar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example1"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Length should be minimum 8 characters</div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.uppercasechar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example2"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">
                    Use minimum one upper case letter (A-Z) & one lower case
                    letter (a-z)
                  </div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      (click)="$event.preventDefault()"
                      [(ngModel)]="passwordstatus.numericchar"
                      [ngModelOptions]="{ standalone: true }"
                      name="example3"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Use minimum one numeric character</div>
                </div>

                <div class="p-dis d-flex-a">
                  <label class="container1">
                    <input
                      type="checkbox"
                      readonly="true"
                      [(ngModel)]="passwordstatus.specialchar"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="$event.preventDefault()"
                      name="example3"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="flex1">Use minimum one special character</div>
                </div>
              </div>
            </form>
            <div class="sign-btn d-flex-ja mt-3" (click)="updatePassword()">
              Confirm Password
            </div>
          </div>
          <div class="forget-footer">
            If you haven't made this request, &nbsp;
            <a href="#"> Click here </a> &nbsp; to login session
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
