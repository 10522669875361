import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private toaster: ToastrService
  ) {}

  public isAuthenticated(key: any): boolean {
    try {
      const token = this.localStorage.getItem(key);
      jwtHelper.decodeToken(token);
      return token ? false : true;
    } catch (error) {
      return true;
    }
  }

  public tokenDecode(): any {
    const token = this.getAdminToken();
    const decodeValue = jwtHelper.decodeToken(token);
    return decodeValue && decodeValue.data ? decodeValue.data : null;
  }

  public setAdminToken(data: any): any {
    this.localStorage.setItem('token', data.token);
    this.localStorage.setItem('admin-id', JSON.stringify(data.admin.id));
  }

  public setAdminId(token: any) {
    this.localStorage.setItem('admin-id', JSON.stringify(token.id));
  }

  public getAdminToken(): string {
    return this.localStorage.getItem('token');
  }

  public getRefreshToken(): string {
    return this.localStorage.getItem('refreshToken');
  }

  public getAdminId(): any {
    return this.localStorage.getItem('admin-id');
  }

  public removeToken() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  // refreshToken
  public refreshToken(): Observable<any> {
    const refreshToken = this.localStorage.getItem('refreshToken');
    if (refreshToken) {
      const url = environment.baseUrl + '/refresh-token?token=' + refreshToken;
      return this.http.get<any>(url).pipe(
        tap(
          (data) => {
            this.localStorage.setItem('token', data.accessToken);
            this.localStorage.setItem('refreshToken', data.refreshToken);
          },
          () => {
            this.toaster.error('Your session has expired');
            this.removeToken();
          }
        )
      );
    } else {
      return EMPTY;
    }
  }

  public initalRoutepath(roleId: any): any {
    let path = '';
    switch (roleId) {
      case 1:
        path = 'product-admin';
        break;
      case 2:
        path = 'org-admin';
        break;
      case 3:
        path = 'manager';
        break;
      case 4:
        path = 'employee';
        break;
      default:
        break;
    }
    return path;
  }
  public redirectTo(roleId: any): any {
    const startPath = this.initalRoutepath(roleId);
    if (startPath === 'product-admin') {
      return `/${startPath}/dashboard`;
    } else if (startPath === 'org-admin') {
      return `/${startPath}/dashboard`;
    } else if (startPath === 'manager') {
      return `/${startPath}/news-feed`;
    } else if (startPath === 'employee') {
      return `/${startPath}/news-feed`;
    }
  }

  // NavBarList
  public sideNavBarList(): Observable<any> {
    const url = environment.baseUrl + '/side-bar';
    return this.http.get(url);
  }

  public logout(): any {
    const refreshToken = this.localStorage.getItem('refreshToken');
    if (refreshToken) {
      const url = environment.baseUrl + '/refresh-token?token=' + refreshToken;
      return this.http.delete(url);
    }
  }
}
