import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomToastrService } from 'src/app/shared/services/custom-toastr.service';

@Injectable({
  providedIn: 'root',
})
export class OrgAdminGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private toastr: CustomToastrService,
    private router: Router
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated('token')) {
      // this.toastr.error('Session expired!!! Please login again');
      this.authService.removeToken();
      return false;
    }
    const token = this.authService.tokenDecode();
    if (token && token.role_id !== 2) {
      const tokenVal = this.authService.tokenDecode();
      const navigatePath = this.authService.redirectTo(tokenVal.role_id);
      this.router.navigate([navigatePath]);
      return false;
    }
    return true;
  }
  canLoad():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated('token')) {
      // this.toastr.error('Session expired!!! Please login again');
      this.authService.removeToken();
      return false;
    }
    const token = this.authService.tokenDecode();
    if (token && token.role_id !== 2) {
      const tokenVal = this.authService.tokenDecode();
      const navigatePath = this.authService.redirectTo(tokenVal.role_id);
      this.router.navigate([navigatePath]);
      return false;
    }
    return true;
  }
}
